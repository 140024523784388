.card {
    border: none;
    background-color: #F9F9FC;

    &-body {
        text-align: center;
    }

    @media (max-width: 992px) {
        margin-bottom: $space-32;

        .card-body {
            padding: $space-24;
        }
    }

    .card-icon {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 80px;
        width: 80px;
        border-radius: 100%;
        padding: $space-16;
        background-color: #fff;
        margin: -54px auto $space-16;
        box-shadow: 0px 1px 35px rgba(0, 0, 0, 0.07);
    }

    &:nth-child(1),
    &:nth-child(4) {
        .card-icon {
            background-color: $primary;
        }
        
    }

    &:nth-child(2) {
        .card-icon {
            background-color: $green-light;
        }
        
    }

    &:nth-child(3) {
        .card-icon {
            background-color: $orange;
        }
        
    }
}

.team-card {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #fff;
    border: 3px solid #E5F2FF;
    border-radius: 16px;

    img {
        border-radius: 100%;

        @media (max-width: 768px) { 
            width: 80px !important;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: start;
        height: 100%;
    }

    &-caption {
        font-size: 14px;
        font-weight: 700;
        color: #00629F;
        text-transform: uppercase;
    }

    &-title {
        font-size: $h4-font-size;
        color: $heading-color-light;
        font-weight: 700;
    }

    &-icons {
        margin-top: auto;
        display: flex;
        gap: 1rem;
        font-size: 20px;
    }
}

.integration-app-card {
    display: flex;
    align-items: center;
    gap: 1rem;

    div:first-child {
        background-color: #F5F4F5;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        height: 72px;
        width: 72px;
        min-width: 72px;
        aspect-ratio: 1;
    }

    &-body {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 18px;
            font-weight: 700;
        }

        p {
            margin: 0;
        }
    }
}

.card-sm {
    color: $white;
    padding: $space-8 $space-16;
    display: flex;
    gap: $space-16;
    align-items: center;
    position: absolute;
    border-radius: 8px;
    max-width: 240px;
    text-align: start;
    box-shadow: $box-shadow;

    @media screen and (max-width: 768px) {
        font-size: $font-size-xs;
        padding: $space-8;
        gap: $space-4;
    }
}

.card-bg-green {
    background: rgba(0, 89.86, 97.79, .9);
    top: -$space-16;
    left: -$space-24*2;

    @media screen and (max-width: 768px) {
        left: -$space-8;
    }
}

.card-bg-blue {
    background: rgba(43, 100, 151, .9);
    bottom: $space-40*3;
    right: -$space-32*2;

    @media screen and (max-width: 768px) {
        right: -$space-8;
    }
}

.card-bg-orange {
    background: rgba(236, 114, 36, .9);
    bottom: $space-24*2;
    left: -$space-24;

    @media screen and (max-width: 768px) {
        left: -$space-8;
    }
}