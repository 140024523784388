@import "../../styles/variables/variables";

.job-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.job-card {
  background-color: #F5FAFF;
  border-radius: 24px;
  padding: 32px;
}

.job-card h5 {
  font-weight: 600;
  font-size: $font-size-lg;
  line-height: 1;
  margin: 0 0 10px;
  color: black;
}

.job-card p {
  margin: 0 0 $space-16;
}

.job-types {
 display: flex;
 gap: 2rem;
 color: #6991CC;
 font-size: $font-size-md;
 line-height: 1;
 font-weight: 500;

 li::marker {
  color: #6991CC;
 }
}

.job-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  min-height: 66px;
}